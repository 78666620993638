import React from 'react'
import { Link } from 'gatsby'


class Button extends React.Component {
  render() {
    const { outlined, external, primary, margin, padding, full, type, text, to, width, inverse } = this.props
    const ButtonClass =
      `inline-block relative ${width ? width : ''} text-center font-bold capitalize px-5 py-1 text-base rounded-3xl`

    const RenderClass = `${ButtonClass} ${
      outlined ? 'border-4 link bg-transparent border-white' : 'bg-secondary'
    } ${primary ? 'card secondary' : 'bg-transparent'} ${margin ? margin : ''} ${full ? 'md:w-full' : ''} ${padding ? padding : ''} ${width ? width : ''} ${inverse ? 'text-gray' : 'text-white'}`

    return (
      <>
        {external ? (
          <a className={RenderClass} target="_blank" href={to} rel="noopener noreferrer">
            {text || `Learn more`}
          </a>
        ) : type ? (
          <button className={RenderClass} type={type}>
            {text || `Learn more`}
          </button>
        ) : (
          <Link className={RenderClass} to={to}>
            {text || `Learn more`}
          </Link>
        )}
      </>
    )
  }
}

export default Button
