import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import Container from './container'

import { logEvent } from '../utils/analytics'



const classColumns = 'w-full md:w-4/12 mb-6'
const classStrong = 'inline-block w-full font-bold text-md mb-4'
const classLinks =
  'inline-block w-full text-md md:w-auto text-gray-300 mb-2 md:mb-4 mr-0 md:mr-8 link py-1 items-center'
const year = new Date().getFullYear()

const MiddleNavLinks = [
  {
    title: 'Home',
    to: '/'
  },
  {
    title: 'Team',
    to: '/team'
  },
  {
    title: 'Services',
    to: '/services'
  },
  {
    title: 'Mission',
    to: '/mission'
  },
  {
    title: 'Careers',
    to: '/careers'
  },
  {
    title: 'Insights',
    to: '/insights'
  }
]


const Footer = class extends React.Component {
  
  constructor(props) {
    super(props)

    this.logClick = this.logClick.bind(this)
    this.getTime = this.getTime.bind(this)
  }

  logClick(name) {
    const event = {
      category: 'Social',
      action: `Clicked ${name}`
    }
    logEvent(event)
  }

  getTime(currentTime = new Date()) {
    const currentHour = currentTime.getHours()
    const splitAfternoon = 12 // 24hr time to split the afternoon
    const splitEvening = 17 // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return 'Good afternoon from'
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return 'Good evening from'
    }
    // Between dawn and noon
    return 'Good morning from'
  }

  

  render() {

    

    const { country, company, email, city } = this.props

    const footerLinks = [
      {
        title: 'Home',
        to: '/'
      },
      {
        title: 'Mission',
        to: '/mission'
      },
      {
        title: 'Team',
        to: '/team'
      },
      {
        title: 'Services',
        to: '/services'
      },
      {
        title: 'Insights',
        to: '/insights'
      },
      {
        title: 'Careers',
        to: '/careers'
      },
      {
        title: 'Events',
        to: '/events'
      },
      {
        title: 'LinkedIn',
        to: 'https://www.linkedin.com/company/gocartograph'
      }
    ]

    const LinkComponent = ({ title, list }) => (
      <div className={classColumns} key={`${title}-${Math.random()}`}>
        <strong className={classStrong}>{title}</strong>
        <ul className="inline-block w-full">
          {list.map((item, index) => (
            <li key={`list-${index}`}>
              {item.external ? (
                <a className={classLinks} target="_blank" href={item.link}>
                  {item.title} {item.cta}
                </a>
              ) : (
                <Link className={classLinks} to={item.link}>
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    )

    return (
      <footer className="flex h-full pt-12 flex-col lg:flex-row dark-green w-full px-24 py-6 ">
        <Container>
          {/* Top line */}
          <div className="flex lg:h-10 items-center flex-col flex-col-reverse lg:flex-row justify-between lg:mb-12 w-full mx-auto">
            {/* Links */}
            <div className="flex flex-col text-center items-center justify-center md:flex-row lg:h-10 font-bold font-3xl"> 
              {footerLinks.map((item, index) => (
                <Link className={classLinks} to={item.to}>
                  {item.title}
                </Link>

              ))}
            </div>
            {/* Logo */}
            <div className=' flex lg:items-center'>
              <img
                className="inline-block w-md lg:w-64 align-middle mr-2 mb-4"
                src="/Cartograph white orange pointer transparent bg.png"
                alt={company}
              />
            
            </div>
          </div>

               

          {/* Bottom line */}
          <div className="inline-block w-full h-10 mb-8">
            <div className="inline-block relative w-full">
              <p className="w-full text-center lg:text-left text-sm text-gray-500">
                &copy; {year} {company}
              </p>
            </div>
            <div className="inline-block text-center lg:text-left w-full ">
              <a
                className="text-xs text-gray-500 underline link"
                target="_blank"
                href={`/privacyPolicy`}
                rel="noopener noreferrer"
              >
                {this.getTime()} {city}
              </a>
            </div>
          </div>
        </Container>
        {/* <script type="text/javascript">{`
        _linkedin_partner_id = "5192748";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `}
        </script>
        <script type="text/javascript">{`
          (function(l) {
          if (!l){
            window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]
          }
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
          s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}
        </script> */}
        {/* <noscript>
        <img height="1" width="1" style="display:none;" alt="" src='https://px.ads.linkedin.com/collect/?pid=5192748&fmt=gif '/>
        </noscript> */}
      </footer>
    )
  }
}

export default Footer
